// Translated
// Migrated
<template lang="pug">
.d-inline-flex(
  v-if="type !== 'inline'"
  :class="classes"
)
  .review-star.d-flex(
    v-for="(i, index) in stars"
    :key="index"
  )
    img(
      v-if="score < i + 0.3"
      :src="empty"
      alt="empty star"
      :width="starSize"
      :height="starSize"
      loading="lazy"
    )
    img(
      v-else-if="score < i + 1"
      :src="half"
      alt="half star"
      :width="starSize"
      :height="starSize"
      loading="lazy"
    )
    img(
      v-else
      :src="full"
      alt="full star"
      :width="starSize"
      :height="starSize"
      loading="lazy"
    )

div(
  v-else
  class="d-contents"
  )
  template(
    v-for="(i, idx) in stars"
    :key="idx"
  )
    img(
      v-if="score < i + 0.3"
      :src="empty"
      alt="empty star"
      :width="starSize"
      :height="starSize"
      loading="lazy"
    )
    img(
      v-else-if="score < i + 1"
      :src="half"
      alt="half star"
      :width="starSize"
      :height="starSize"
      loading="lazy"
    )
    img(
      v-else
      :src="full"
      alt="full star"
      :width="starSize"
      :height="starSize"
      loading="lazy"
    )
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  props: {
    fullstars: {
      type: Boolean,
      default: false
    },

    score: {
      type: Number,
      required: true
    },

    size: {
      type: String,
      default: 'sm',
      validator: value => ['sm', 'lg'].includes(value)
    },

    classes: {
      type: String,
      default: ''
    },

    type: {
      type: String,
      default: 'container',
      validator: value => ['container', 'inline'].includes(value)
    },

    isStar: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      stars: [...Array(5).keys()]
    }
  },

  computed: {
    ...mapState(useLocaleStore, {
      isCharter: 'isCharter'
    }),

    starSize () {
      if (this.size === 'lg') {
        return 20
      }

      return 16
    },

    selectIcon () {
      return !this.isCharter || this.isStar
    },

    empty () {
      return this.selectIcon ? `/icons/star${this.fullstars ? '-box' : ''}-empty.svg` : '/icons/solresor-empty.svg'
    },

    half () {
      return this.selectIcon ? `/icons/star${this.fullstars ? '-box' : ''}-half.svg` : '/icons/solresor-half.svg'
    },

    full () {
      return this.selectIcon ? `/icons/star${this.fullstars ? '-box' : ''}-full.svg` : '/icons/solresor-full.svg'
    }
  }
})
</script>
